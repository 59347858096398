import React from "react";

// Tailwind React UI
import { Box, FillButton, Flex, Text } from "tailwind-react-ui";

const InfoRow = ({ block }) => {
  return (
    <>
      {block.map((col, i) => (
        <Flex
          key={`col${i}`}
          text={block.colour}
          align="start"
          wrap
          justify="between"
          className={`transition duration-300 ease-in-out  border border-${block.colour} border-1 mb-5 hover:bg-gray-300 hover:text-primary`}
          data-component-type="info-row"
        >
          {col.text.map((text, j) => (
            <Flex
              key={`text${j}`}
              align="start"
              wrap
              justify="between"
              className="flex-grow"
            >
              {text.column1 ? (
                <Box
                  className="pt-4 px-4 lg:p-6 w-full lg:w-1/5"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <Text font="basic" leading="tight">
                    <strong>{text.column1}</strong>
                  </Text>
                </Box>
              ) : (
                ""
              )}
              {text.column2 ? (
                <Box
                  className="pt-4 px-4 lg:p-6 w-1/2 sm:w-1/4 md:w-1/4 lg:w-1/5"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <Text font="basic" leading="tight">
                    {text.column2}
                  </Text>
                </Box>
              ) : (
                ""
              )}
              {text.column3 ? (
                <Box
                  className="pt-4 px-4 lg:p-6 w-1/2 sm:w-1/4 md:w-1/4 lg:w-1/5"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <Text font="basic" leading="tight">
                    {text.column3}
                  </Text>
                </Box>
              ) : (
                ""
              )}
              {text.column4 ? (
                <Box
                  className="pt-4 px-4 lg:p-6 w-1/2 sm:w-1/4 md:w-1/4 lg:w-1/5"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <Text font="basic" leading="tight">
                    {text.column4}
                  </Text>
                </Box>
              ) : (
                ""
              )}
              {text.column5 ? (
                <Box
                  className="pt-4 px-4 lg:p-6 w-1/2 sm:w-1/4 md:w-1/4 lg:w-1/5"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <Text font="basic" leading="tight">
                    {text.column5}
                  </Text>
                </Box>
              ) : (
                ""
              )}
            </Flex>
          ))}
          {col.buttonUrl || col.buttonRelatedEntry ? (
            <Box className="w-auto lg:w-auto mr-auto my-auto pb-2 lg:pb-0 lg:ml-auto text-left lg:text-right lg:mr-0">
              <FillButton
                font="display"
                is="a"
                href={
                  col.buttonUrl ??
                  (col.buttonRelatedEntry.length > 0
                    ? "/" + col.buttonRelatedEntry[0].uri
                    : "#")
                }
                data-type="button"
                bg="white"
                text="primary"
                text-hocus="white"
                leading="tight"
                className="btn p-3 lg:px-5 leading-relaxed rounded-full my-3 mx-4 lg:ml-auto text-sm"
              >
                <span className="block relative">
                  {col.buttonText ?? "View more"}
                </span>
              </FillButton>
            </Box>
          ) : (
            ""
          )}
        </Flex>
      ))}
    </>
  );
};

export default InfoRow;
