import * as React from "react";
import { graphql, Link } from "gatsby";

// Layout
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import { buildFluidImageData } from "@imgix/gatsby";

// Custom Components
import ImgixFluidImage from "../../components/ImgixFluidImage";
import Buttons from "../../components/Matrix/types/Independent/Buttons";
import InfoRow from "../../components/Matrix/types/Independent/InfoRow";
import PageBanner from "../../components/PageBanner";
import Matrix from "../../components/Matrix";

// Tailwind React UI Components
import {
  Box,
  Container,
  Flex,
  Title,
  Section,
  Subtitle,
  Text,
  ContentBlock,
} from "tailwind-react-ui";

// Hard-coded image imports
import BlockBlue from "../../images/patterns/pattern-block_blue.svg";
import Frame03 from "../../images/patterns/pattern-frame03.svg";
import TriangleGY from "../../images/patterns/patterns-triangles_greenyellow.svg";

// GraphQL
export const query = graphql`
  query TicketPricesPageQuery {
    entry: craftPlanYourVisitPlanYourVisitEntry {
      id
      uri
      url
      title
      subtitle
      summary
      featureImage {
        ...UploadAssetFragment
      }
      displayTitle
      metaTitle
      metaDescription
      metaKeywords
      metaImage {
        ...UploadAssetFragment
      }
      journey {
        ... on Craft_journey_step_BlockType {
          id
          heading
          image {
            ...UploadAssetFragment
          }
          icon {
            url
          }
          iconBackgroundColour
          text
        }
      }
      introductionRow {
        ...IntroductionRow
      }
      applicableDates
      applicableDatesNew
      bodyContent {
        ...BodyContentFragment
      }
      infoRow {
        ...InfoRowFragment
      }
      infoRowNew {
        ...InfoRowNewFragment
      }
      additionalText
      seasonalHours {
        ...SeasonalHoursFragment
      }
      yellowContentSlider {
        ... on Craft_yellowContentSlider_slide_BlockType {
          heading
          image {
            ...UploadAssetFragment
          }
          text
          buttonText
          buttonUrl
          relatedEntry {
            uri
          }
        }
      }
      quickTips {
        ...QuickTipsFragment
      }
      heading {
        ... on Craft_heading_heading_BlockType {
          id
          text
        }
      }
      accordion {
        ... on Craft_accordion_accordionRow_BlockType {
          id
          heading
          text
        }
      }
      imageOnly {
        ...UploadAssetFragment
      }
      richText
      buttons {
        ...ButtonsFragment
      }
      signUpBanner {
        ...SignUpBannerFragment
      }
    }
    siteInfo: craftSiteInformationGlobalSet {
      ...SiteInfoFragment
    }
    termsEntry: craftPlanYourVisitPagesPlanYourVisitEntry(
      slug: { eq: "terms-and-conditions" }
    ) {
      id
      uri
      url
      title
      remoteId
      typeHandle
      subtitle
      postDate
      featureImage {
        ...UploadAssetFragment
      }
      metaTitle
      metaDescription
      metaKeywords
      metaImage {
        ...UploadAssetFragment
      }
      bodyContent {
        ...BodyContentFragment
      }
    }
  }
`;

const truncateString = (string = "", maxLength = 54) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

// {data.site.title}
const TicketPrices = ({ data: { entry, siteInfo, termsEntry } }) => {
  const subtitle = entry.subtitle || siteInfo.siteTitle;
  const [siteImage] = siteInfo.siteImage;
  const [featureImage = siteImage] = entry.featureImage;
  const metaImage = featureImage || entry.metaImage || siteInfo.siteImage;
  const metaDescription = truncateString(
    (
      entry.metaDescription ||
      entry.summary ||
      siteInfo.siteDescription
    ).replace(/(<([^>]+)>)/gi, ""),
    159
  );
  const metaTitle = truncateString(
    `${entry.metaTitle || entry.displayTitle || entry.title}`,
    53
  );
  const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null;

  const metaImageFormatted = buildFluidImageData(`${metaImage.url}`, {
    ar: 1, // required
    auto: ["format", "compress"],
    sizes: `300px, 300px`, // optional, but highly recommended
    width: 300,
    height: 300,
  });

  return (
    <Layout location={entry.url} title={metaTitle}>
      <Seo
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        image={metaImageFormatted.src}
        url={entry.url}
        pathname={`/${entry.uri}`}
      />
      <h1 style={{ fontSize: 0, color: "transparent" }} className="sr-only">
        {metaTitle}
      </h1>

      <Section p="0" className="p-0 relative">
        <PageBanner
          title={"Ticket Prices"}
          subtitle={subtitle}
          image={featureImage}
        />
      </Section>

      <Section
        id="prices"
        bg="gray-light"
        className="relative text-center"
        p="0"
      >
        <img
          src={entry.introductionRow[0].image[0].url}
          width="128"
          className="absolute top-5 right-0 hidden lg:block"
          alt={entry.title}
        />
        <Container className="pb-8 pt-14 lg:pt-20 lg:pb-16">
          <Title
            level={3}
            text="primary"
            font="heading"
            className="text-primary font-heading lg:text-5xl relative flex justify-center flex-start"
          >
            {entry.introductionRow[0].heading}
            <TriangleGY className="ml-6 -mt-1" alt="Triangles" />
          </Title>
          <ContentBlock className="lg:w-2/3 mx-auto">
            <Text
              className="text-md"
              dangerouslySetInnerHTML={{
                __html: entry.introductionRow[0].text,
              }}
            />
          </ContentBlock>
        </Container>
      </Section>

      <Section
        id="types"
        className="text-white bg-blue-dark relative p-0"
        p="0"
      >
        <Container className="xl:w-5/6 py-14 lg:py-20">
          <Title
            text="white"
            level={3}
            font="heading"
            className="font-heading text-white"
          >
            Ticket Types
          </Title>

          {entry.applicableDatesNew && (
            <Box className="relative w-full lg:flex justify-start items-center mb-5">
              <Text is="p" className="text-white pb-0 mb-0 flex-1">
                <span className="w-full md:w-4/5">
                  {entry.applicableDatesNew}
                </span>
              </Text>
              <Box className="w-full md:w-2/5 flex">
                <Link
                  className="mt-6 mb-5 sm:my-3 lg:ml-auto mr-5 flex items-baseline lg:justify-end"
                  to="/about-us/privacy-policy"
                >
                  <span className="text-center text-sm block w-5 h-5 border border-secondary text-secondary rounded-full mr-2 leading-0 font-bold">
                    ?
                  </span>
                  <span className="text-primary block text-xs px-3 py-2 rounded-full border-white focus:border-white hover:border-white whitespace-nowrap bg-white focus:bg-secondary hover:bg-secondary font-basic font-bold">
                    Privacy Policy
                  </span>
                </Link>
                <Link
                  className="mt-6 mb-5 sm:my-3 sm:mr-auto lg:mr-0 flex items-baseline lg:justify-end"
                  to="/plan-your-visit/terms-and-conditions"
                >
                  <span className="text-center text-sm block w-5 h-5 border border-secondary text-secondary rounded-full mr-2 leading-0 font-bold">
                    ?
                  </span>
                  <span className="text-primary block text-xs px-3 py-2 rounded-full border-white focus:border-white hover:border-white whitespace-nowrap bg-white focus:bg-secondary hover:bg-secondary font-basic font-bold">
                    Terms and Conditions
                  </span>
                </Link>
              </Box>
            </Box>
          )}

          {entry.infoRowNew.length > 0 && <InfoRow block={entry.infoRowNew} />}

          {entry.applicableDates && (
            <Box className="relative w-full lg:flex justify-start items-center mb-5">
              <Text is="p" className="text-white pb-0 mb-0 flex-1">
                <span className="w-full md:w-4/5">{entry.applicableDates}</span>
              </Text>
              <Box className="w-full md:w-2/5 flex">
                <Link
                  className="mt-6 mb-5 sm:my-3 lg:ml-auto mr-5 flex items-baseline lg:justify-end"
                  to="/about-us/privacy-policy"
                >
                  <span className="text-center text-sm block w-5 h-5 border border-secondary text-secondary rounded-full mr-2 leading-0 font-bold">
                    ?
                  </span>
                  <span className="text-primary block text-xs px-3 py-2 rounded-full border-white focus:border-white hover:border-white whitespace-nowrap bg-white focus:bg-secondary hover:bg-secondary font-basic font-bold">
                    Privacy Policy
                  </span>
                </Link>
                <Link
                  className="mt-6 mb-5 sm:my-3 sm:mr-auto lg:mr-0 flex items-baseline lg:justify-end"
                  to="/plan-your-visit/terms-and-conditions"
                >
                  <span className="text-center text-sm block w-5 h-5 border border-secondary text-secondary rounded-full mr-2 leading-0 font-bold">
                    ?
                  </span>
                  <span className="text-primary block text-xs px-3 py-2 rounded-full border-white focus:border-white hover:border-white whitespace-nowrap bg-white focus:bg-secondary hover:bg-secondary font-basic font-bold">
                    Terms and Conditions
                  </span>
                </Link>
              </Box>
            </Box>
          )}
          <InfoRow block={entry.infoRow} />
        </Container>
      </Section>

      <Section id="refunds" p="0" className="bg-gray-light p-0">
        <Container className="pt-14 pb-8 lg:py-20">
          <Flex
            wrap
            align="start"
            justify="between"
            className="flex-wrap items-start justify-between xl:w-5/6 mx-auto"
          >
            <Box className="w-full sm:w-2/3 lg:w-2/5 px-5 sm:px-10 py-4 mx-auto lg:ml-0">
              <div className="relative w-full">
                <BlockBlue className="absolute -top-5 -left-5" alt="Frame" />
                <Frame03
                  className="z-20 absolute -top-3 -left-9 h-full sm:h-auto sm:w-full"
                  alt="Frame"
                />
                <ImgixFluidImage
                  className="w-full bg-gray-light rounded-lg"
                  alt={
                    entry.imageOnly[0].imageAlt ??
                    entry.imageOnly[0].title ??
                    entry.imageOnly[0].filename.replace(/[^a-z0-9+]+/gi, " ")
                  }
                  imageUrl={entry.imageOnly[0].url}
                  ar={1 / 1}
                />
              </div>
            </Box>
            <Box className="w-full lg:w-3/5 xl:w-3/5 p-4 overflow-hidden">
              <Title level={3} font="heading" className="font-heading title">
                Refunds
              </Title>
              <Subtitle
                level={6}
                size={2}
                font="heading"
                className="font-heading"
              >
                Claiming your Cableway ticket refund is easy with just a click
                of a button{" "}
              </Subtitle>
              <div dangerouslySetInnerHTML={{ __html: entry.richText }} />
              {entry.buttons[0] ? (
                <Flex justify="start">
                  <Buttons block={entry.buttons} />
                </Flex>
              ) : (
                ""
              )}
            </Box>
          </Flex>
        </Container>
      </Section>
      {termsEntry.bodyContent.length ? (
        <Section p="0" className="secondary-page">
          <Container className="xl:w-2/3 pb-18 pt-16">
            <Matrix blocks={termsEntry.bodyContent} />
          </Container>
        </Section>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default TicketPrices;
